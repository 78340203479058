@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'), url('../fonts/Roboto.woff2') format('woff2'), url('../fonts/Roboto.woff') format('woff'), url('../fonts/Roboto.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'), url('../fonts/Robotomedium.woff2') format('woff2'), url('../fonts/Robotomedium.woff') format('woff'), url('../fonts/Robotomedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

/*Reset*/
*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
}
a {
    text-decoration: none;
}
ul,
ol,
li {
    list-style: none;
}
img {
    vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
    font-size: inherit;
}
p {
    transition: .3s;
}
/*Reset*/
html {
    background: var(--bg-color);
}
html,
body {
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    overflow: hidden;
    height: 100vh;
    height: 100svh;
    width: 100vw;

    display: flex;
    flex-direction: column;

    font-family: var(--font-main);
    font-weight: 400;
    font-size: 14px;
    color: var(--font-color);

    /*for mac os*/
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: subpixel-antialiased !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}
button {
    font-family: var(--font-main);

    /*for mac os*/
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: subpixel-antialiased !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}
.wrapper {
    max-width: 634px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 22px;
}

/*BUTTON*/
.button {
    width: 100%;
    height: 32px;

    background: var(--color-accent);

    color: #FFF;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;

    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    white-space: nowrap;
    outline: none;
    cursor: pointer;

    transition: .3s;

    position: relative;
}
.button.black {
    background: rgba(9, 17, 29, 0.50);
    backdrop-filter: blur(8px);
}
/*BUTTON END*/

/*SCROLLBARS*/
::-webkit-scrollbar {
    width: 4px;
    background: var(--scroll-bg);
}
::-webkit-scrollbar-thumb {
    border-width: 1px 1px 1px 2px;
    background-color: var(--scroll-thumb);
    -webkit-border-radius: 2px;
}
::-webkit-scrollbar-track {
    border-width: 0;
}
:root {
    scrollbar-color: var(--scroll-thumb) var(--scroll-bg) !important;
    scrollbar-width: thin !important;
}
/*SCROLLBARS END*/

/*MAIN*/
.main {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
}

.screen {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.screen-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.screen-bg__fade {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    /*max-width: 1024px;*/
}
.screen-bg__fade.top {
    top: -72px;
    height: 295px;
    background: linear-gradient(0deg, rgba(9, 17, 29, 0.00) 0%, #09111D 100%);
}
.screen-bg__fade.bottom {
    bottom: 0;
    height: 262px;
    background: linear-gradient(180deg, rgba(9, 17, 29, 0.00) 0%, #09111D 100%);
}
.screen-bg__img {
    width: 100%;
    /*max-width: 1024px;*/
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
}

.screen-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.scroll {
    overflow-y: auto;
    padding-bottom: 145px;
}
/*MAIN END*/

/*HOME*/
.home {
    height: 100vh;
    height: 100svh;
    padding-top: 36px;
    padding-bottom: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.home-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.home-top__menu {
    width: 100%;
    display: grid;
    grid-template-columns: .25fr .17fr .32fr .26fr;
    gap: 32px;
}
.home-top__menu-link {
    width: 100%;
    color: #FFF;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    padding: 10.5px 10px;
    border-radius: 10px;
    background: rgba(9, 17, 29, 0.50);
    backdrop-filter: blur(8px);
    text-align: center;
}
.home-top__menu-link.notify {
    position: relative;
}
.home-top__menu-link.notify::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    top: 0;
    right: -1px;
    position: absolute;
    background: var(--color-accent);
    border-radius: 50%;
}
.home-top__menu-link.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.home-top__lang {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.home-top__lang-content {
    position: absolute;
    background: var(--gradient);
    width: 100%;
    top: calc(100% + 6px);
    padding: 12px;
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    border-radius: 10px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.25);
}
.home-top__lang-content a {
    color: #000;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
}
.home-top__lang-content a.active {
    text-decoration: underline;
}
.home-top__lang.show .home-top__lang-content {
    display: flex;
}

.home-top__nickname {
    margin-top: 33px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}
.home-top__balance {
    margin-top: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 45px;
    line-height: 52px;
    letter-spacing: 0;
}
.home-top__balance-icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.home-monkey {
    /*max-height: 1119.55px;*/
    user-select: none;
}
.home-monkey__block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin-top: 20px;*/
    /*margin-top: -100px;*/
    margin-top: -70px;
    /*height: 600px;*/
}
.home-monkey__img {
    /*height: calc(100vh - 36px - 145px - 161px - 130px - 47px - 25px);*/
    /*height: calc(100svh - 36px - 145px - 161px - 130px - 47px - 25px);*/
    height: calc(100vh - 36px - 145px - 161px - 47px - 25px);
    height: calc(100svh - 36px - 145px - 161px - 47px - 25px);
    max-width: calc(100vw - 36px);
    aspect-ratio: 276.84 / 526;
    /*height: 100%;*/
}
.home-monkey__zoom {
    left: 1.5%;
    margin-left: 45%;
    width: 100%;
    z-index: -1;
    position: relative;
    top: 100px;

}

.home-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.home-bottom__boost {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.home-bottom__boost-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}
.home-bottom__boost-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}
.home-bottom__boost-number span.big {
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0;
}
.home-bottom__boost-bar {
    width: 100%;
    margin-top: 7px;
    height: 11px;
    border-radius: 10px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.25);
    overflow: hidden;
}
.home-bottom__boost-progress {
    height: 100%;
    display: block;
    border-radius: 10px;
    border: 1px solid #FFF;
    background: #FF7F37;
}
.home-bottom__lvl {
    width: 100%;
    margin-top: 33px;
}
.home-bottom__lvl-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.home-bottom__lvl-number {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}
.home-bottom__lvl-upgrade {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
}
.home-bottom__lvl-dots {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(45, 1fr);
    gap: 3px;
    margin-top: 12px;
}
.home-bottom__lvl-dot {
    display: block;
    background: var(--color-accent);
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
}
.home-bottom__lvl-dot.disabled {
    opacity: .25;
}
/*HOME END*/

/*MENU*/
.footer {
    width: 100%;
    max-width: 634px;
    position: fixed;
    bottom: 36px;
    left: 50%;
    transform: translateX(-50%);
}
.menu {
    width: 100%;
    padding: 18px 48px 17px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background: rgba(9, 17, 29, 0.75);
    backdrop-filter: blur(8px);
}
.menu-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}
.menu-link__icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-bottom: 6px;
}
/*MENU END*/

/*P2P*/
.p2p {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 36px;
}
.p2p-banner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    background: url(../img/monkeys/banner-bg.png) no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: 80px;
    gap: 16px;
    padding: 16px 24px;
    margin-bottom: 32px;
}
.p2p-banner__text {
    text-align: left;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    max-width: 208px;
    position: relative;
    z-index: 1;
}
.p2p-banner__monkey {
    position: absolute;
    right: 168px;
    bottom: 0;
}
.p2p-banner .button {
    width: 98px;
    min-width: 98px;
    height: 32px;
}

.p2p-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.p2p-buttons__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 11px;
}
.p2p-buttons__button {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    color: #000;
    text-align: center;
    background: var(--gradient);
    border-radius: 10px;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    cursor: pointer;
    opacity: .5;
}
.p2p-buttons__button.active {
    opacity: 1;
}
.p2p-buttons__reload {
    width: 37px;
    height: 37px;
    background: var(--gradient);
    border-radius: 10px;
}
.p2p-buttons__reload-icon {
    width: 20px;
    height: 20px;
}

.p2p-form {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 37px;
    background: var(--gradient);
    border-radius: 10px;
    padding: 0 10px;
}
.p2p-form__delimiter {
    height: 18px;
    width: 1px;
    display: block;
    background: rgba(0, 0, 0, 0.2);
    margin: 0 16px;
}
.p2p-form__input {
    width: 100%;
    height: 100%;
    padding: 0;
    font-family: var(--font-main), sans-serif;
    color: var(--font-color-black);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    background: unset;
    outline: unset;
}
.p2p-form__input::placeholder {
    color: rgba(0, 0, 0, .5);
}
.p2p-form__select {
    height: 100%;
}
.p2p-form__select .custom-select-opener {
    border: unset;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding-left: 0;
    padding-right: 10px;
    white-space: nowrap;
}
.p2p-form__select .custom-select-opener::after {
    right: 0;
}
.p2p-form__select .custom-select-panel {
    width: calc(100% + 20px);
    left: -10px;
}
.p2p-form__select .custom-select-option {
    padding-left: 10px;
    padding-right: 13px;
}
.p2p-form__select .custom-select-container.is-open .custom-select-opener::after {
    top: 11px;
}

.p2p-orders {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
}
.p2p-orders__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: var(--gradient);
    padding: 20px;
    border-radius: 10px;
}
.p2p-orders__item-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.p2p-orders__item-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
.p2p-orders__item-avatar {
    width: 32px;
    height: 32px;
    object-fit: contain;
    min-width: 32px;
    border-radius: 10px;
}
.p2p-orders__item-username {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.p2p-orders__item-stats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.p2p-orders__item-text {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
}
.p2p-orders__item-text.gray {
    color: rgba(0, 0, 0, .5);
}
.p2p-orders__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin: 12px 0 8px;
}
.p2p-orders__item-amount {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}
.p2p-orders__item-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.p2p-orders__item-name {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    position: relative;
    margin-left: 9px;
}
.p2p-orders__item-name::before {
    display: block;
    content: '';
    position: absolute;
    left: -9px;
    width: 3px;
    height: 18px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.25);
}
.p2p-orders__item-footer .button {
    width: 107px;
    height: 32px;
}

.p2p-tabs {
    width: 100%;
}
.p2p-tab {
    width: 100%;
    display: none;
}
.p2p-tab.active {
    display: block;
}
/*P2P END*/

/*POPUP*/
.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 10;
}
.modal.active {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: var(--gradient);
    box-shadow: 0 -4px 40px 0 rgba(0, 0, 0, 0.25);
    padding: 36px;
    height: auto;
    max-width: 348px;
    width: 100%;
}

.modal-content__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto auto;
}
.modal-content__title {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0;
}
.modal-content__close {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    top: 20px;
}
.modal-content__form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal-content__balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    color: #000;
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: 0;
    margin-top: 17px;
}
.modal-content__balance-icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
}
.modal-content__input {
    margin-top: 24px;
    width: 100%;
    padding-left: 12px;
    border-radius: 10px;
    border: 1px solid rgba(35, 30, 50, 0.4);
    height: 32px;
    font-family: var(--font-main), sans-serif;
    color: var(--font-color-black);
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    background: unset;
    outline: unset;
}
.modal-content__input::placeholder {
    color: #231E32;
}
.modal-content__form .button {
    margin-top: 24px;
}
.modal-content__link {
    color: #231E32;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    margin-top: 10px;
}
.modal-content__link.disabled {
    opacity: .5;
    pointer-events: none;
}

.modal-content__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content__form.order {
    align-items: flex-start;
}
.modal-content__form.order .button {
    margin-top: 16px;
}
.modal-content__form.order .modal-content__input {
    margin-top: 16px;
}

.modal-content__order-select {
    width: 100%;
    margin-top: 16px;
    height: 32px;
}
.modal-content__order-select .custom-select-opener span {
    position: relative;
}
.modal-content__order-select .custom-select-opener span::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: -9px;
    height: 12px;
    width: 3px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.25);
}
.modal-content__order-title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}
.modal-content__order-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: 6px;
}
.modal-content__order-text span {
    color: #231E32;
    font-weight: 500;
}
.modal-content__order-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
    gap: 6px;
}
.modal-content__order-group .modal-content__order-text {
    margin-top: 0;
}

@media screen and (max-width: 634px) {
    .modal-content {
        max-width: 316px;
        padding: 28px 22px;
    }
    .modal-content__close {
        top: 10px;
        right: 10px;
    }

    .modal.order .modal-content {
        padding: 22px;
    }
}
/*POPUP END*/

/*RATING*/
.rating {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 36px;
}
.rating-title {
    color: #FFF;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0;
    margin-bottom: 24px;
}
.rating-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}
.rating-list__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    position: relative;
}
.rating-list__group::before {
    position: absolute;
    content: '';
    left: -14px;
    top: -10px;
    width: calc(100% + 28px);
    height: calc(100% + 20px);
    border-radius: 10px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.10);
    z-index: -1;
}
.rating-list__item {
    width: 100%;
    display: grid;
    grid-template-columns: 19% 1fr .5fr .7fr;
    gap: 24px;
}
.rating-list__item-text {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}
.rating-list__item-text:nth-child(1) {
    text-align: left;
}
.rating-list__item-text:nth-child(2) {
    text-align: left;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.rating-list__item-text:nth-child(3) {
    text-align: right;
}
.rating-list__item-text:nth-child(4) {
    text-align: right;
}

.rating-list__item.active {
    position: relative;
    grid-template-columns: 19% 2.2fr;
}
.rating-list__item.active::before {
    content: '';
    position: absolute;
    left: -14px;
    top: -10px;
    height: calc(100% + 20px);
    width: calc(100% + 28px);
    background: var(--gradient);
    z-index: -1;
    border-radius: 10px;
}
.rating-list__item.active .rating-list__item-text {
    color: #000;
}
.rating-list__item.active .rating-list__item-text:nth-child(2) {
    white-space: break-spaces;
    text-overflow: unset;
    overflow: unset;
    word-break: break-word;
}
.rating-list__item.active .rating-list__item-text:nth-child(3) {
    display: none;
}
.rating-list__item.active .rating-list__item-text:nth-child(4) {
    display: none;
}
/*RATING END*/

/*COMBO*/
.combo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
}
.combo-row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 22px;
}
.combo-row__item {
    height: 100%;
    width: 100%;
    aspect-ratio: 128.95 / 105;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    border-radius: 14px;
    background: #75BEE7;
}
.combo-row__item-icon {
    width: 23.86px;
    height: 27.96px;
}
.combo-row__item-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
}
.combo-row__item-delete {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 2;
    width: 28px;
    height: 28px;
    background: #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.combo-row__item-cross {
    width: 18px;
    height: 18px;
}
.combo-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}
.combo-grid__item {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    border-radius: 18.37px;
}
.combo-grid__item-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.combo-grid__item.selected {
    opacity: .5;
    pointer-events: none;
}
/*COMBO END*/

/*TASKS*/
.tasks {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 36px;
}
.tasks-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tasks-header__icon {
    width: 121px;
    height: 121px;
    object-position: center;
    object-fit: contain;
}
.tasks-header__title {
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0;
    margin-top: 16px;
}
.tasks-list {
    margin-top: 36px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}
.tasks-list__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 10px;
    background: var(--gradient);
}
.tasks-list__item.green {
    border: 2px #7BFFA0 solid;
}
.tasks-list__item.disabled {
    opacity: 0.25;
    pointer-events: none;
}
.tasks-list__item-place {
    width: 76px;
    min-width: 76px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tasks-list__item-icon {
    max-width: 36px;
    object-fit: contain;
}
.tasks-list__item-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    padding: 9px 16px 9px 0;
}
.tasks-list__item-name {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}
.tasks-list__item-bonus {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
}
.tasks-list__item-coin {
    width: 17px;
    min-width: 17px;
    height: 17px;
    object-fit: contain;
    object-position: center;
}
.tasks-list__item-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding-right: 28px;
}
.tasks-list__item-number {
    color: #000;
    text-align: right;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0;
}
/*TASKS END*/

/*FRIENDS*/
.friends {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.friends-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
}
.friends-top__title {
    color: #FFF;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
}
.friends-top__reload {
    width: 20px;
    height: 20px;
}
.friends-list {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 200px;
    height: 200px;
    overflow-y: auto;
    border-radius: 10px;
    margin-top: 20px;
    background: var(--gradient);
}
.friends-list__item {
    width: 100%;
    display: grid;
    grid-template-columns: 16px minmax(auto, 160px) auto auto;
    gap: 5px;
}
.friends-list__item-star {
    width: 16px;
    height: 16px;
}
.friends-list__item-text {
    color: #000;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
}
.friends-list__item-text:last-child {
    text-align: right;
}
.friends-list__item-coin {
    width: 17px;
    height: 17px;
}
.friends-list__empty {
    color: #000;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}
.friends-bottom {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
}
.friends-bottom .button {
    height: 37px;
}

.friends-list__item.header .friends-list__item-text {
    color: rgba(0, 0, 0, 0.25);
}
/*FRIENDS END*/

/*LEVEL*/
.level {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 54px;
}
.level-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.level-top__img {
    width: 197px;
    height: 197px;
    object-fit: contain;
    object-position: center;
    border-radius: 50%;
}
.level-top__number {
    margin-top: 24px;
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0;
}
.level-top .home-bottom__lvl-upgrade {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-top: 28px;
}
.level-top .home-bottom__lvl-dots {
    margin-top: 20px;
}
.level-top .home-top__balance {
    margin-top: 14px;
}
/*LEVEL END*/

/*BOOST*/
.boost {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 28vh;
}
.boost-cooldown {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-top: 20px;
}
.boost-button {
    height: 61px;
    margin-top: 20px;
    display: flex;
    font-size: 12px;
    flex-direction: column;
}
.boost-button span {
    font-size: 11px;
}
/*BOOST END*/


/* LOTTIE ANIMATION */
.home-monkey__lottie{
    min-width: 70% !important;
    min-height: 70% !important;
    position: absolute;
    display: block;
}
.home-monkey__animation {
    will-change: transform, opacity;
  }