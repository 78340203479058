.error-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.error-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.error-modal h2 {
    margin-top: 0;
}

.error-modal button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #ff4d4f;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.error-modal button:hover {
    background-color: #ff7875;
}
