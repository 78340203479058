:root {
    --font-main: Roboto, 'Roboto', sans-serif;

    --font-color: #FFFFFF;
    --font-color-gray: #D9D9D9;
    --font-color-black: rgb(0, 0, 0);

    --scroll-bg: #FFFFFF;
    --scroll-thumb: #FF7F37;

    --bg-color: #c1c1c1;

    --color-accent: #FF7F37;

    --gradient: linear-gradient(180deg, #FFF 0%, #8DE4FF 100%);
}