/*MAIN MEDIA*/
@media screen and (max-width: 634px) {
    .footer {
        bottom: 12px;
    }
    .menu {
        padding: 15px 15px 10px;
        justify-content: space-around;
    }
    .menu-link {
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.5px;
    }

    .home {
        padding-top: 22px;
        padding-bottom: 99px;
    }
    .home-top__menu {
        gap: 16px;
    }
    .home-top__menu-link {
        padding: 10.5px 6px;
    }
    .home-top__nickname {
        margin-top: 12px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.5px;
    }
    .home-top__balance {
        gap: 6px;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: 0;
        margin-top: 4px;
    }
    .home-bottom__lvl-dots {
        gap: 2px;
    }
    .home-bottom__lvl-number {
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
    }
    .home-bottom__lvl-upgrade {
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
    }
    .home-bottom__lvl {
        margin-top: 9px;
    }
    .home-bottom__boost-bar {
        margin-top: 6px;
    }
    .home-bottom__boost-number {
        gap: 4px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
    .home-bottom__boost-number span.big {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0;
    }

    .home-monkey__block {
        margin-bottom: -35px;
        margin-top: -105px;
    }
    .home-monkey__img {
        /*height: calc(100vh - 22px - 99px - 113px - 97px - 35px - 35px);*/
        /*height: calc(100svh - 22px - 99px - 113px - 97px - 35px - 35px);*/

        /*height: calc(100vh - 22px - 99px - 113px - 35px - 35px);*/
        /*height: calc(100svh - 22px - 99px - 113px - 35px - 35px);*/

        height: calc(100vh - 22px - 99px - 113px);
        height: calc(100svh - 22px - 99px - 113px);
        max-width: calc(100vw - 110px);
    }

    .scroll {
        padding-bottom: 99px;
    }

    .p2p {
        padding-top: 22px;
    }
    .p2p-banner {
        height: 72px;
        padding: 16px;
        margin-bottom: 28px;
    }
    .p2p-banner__monkey {
        right: 34px;
    }
    .p2p-banner__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
    .p2p-form {
        margin-top: 14px;
    }
    .p2p-orders {
        gap: 12px;
        margin-top: 13px;
    }
    .p2p-orders__item {
        padding: 12px;
    }

    .rating {
        padding-top: 22px;
    }
    .rating-list {
        gap: 16px;
    }
    .rating-list__group {
        gap: 16px;
    }
    .rating-list__item-text {
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
    }
    .rating-list__item {
        grid-template-columns: 15% 1fr .5fr .7fr;
        gap: 16px;
    }
    .rating-list__item.active {
        grid-template-columns: 15% 2.2fr;
    }

    .combo {
        gap: 22px;
    }
    .combo-row {
        gap: 12px;
    }
    .combo-row__item {
        border-radius: 8px;
    }
    .combo-row__item-delete {
        width: 16px;
        height: 16px;
    }
    .combo-row__item-cross {
        width: 10px;
        height: 10px;
    }
    .combo-grid {
        gap: 12px;
    }

    .tasks {
        padding-top: 26px;
    }
    .tasks-header__icon {
        width: 76px;
        height: 76px;
    }
    .tasks-header__title {
        margin-top: 13px;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0;
    }
    .tasks-list {
        margin-top: 22px;
        gap: 8px;
    }
    .tasks-list__item-place {
        width: 66px;
        height: 50px;
    }
    .tasks-list__item-column {
        padding: 6px 16px 7px 0;
        gap: 0;
    }
    .tasks-list__item-name {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
    .tasks-list__item-bonus {
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
    }

    .boost .tasks-list {
        margin-top: 32px;
    }
    .boost-cooldown {
        margin-top: 11px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
    .boost-button {
        height: 50px;
        margin-top: 8px;
    }

    .friends-top {
        margin-top: 24px;
    }
    .friends-top__title {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
    }
    .friends-list {
        height: 111px;
        margin-top: 12px;
        padding: 12px 20px 12px 12px;
    }
    .friends-bottom {
        gap: 11px;
        margin-top: 11px;
    }
    .friends-list__empty {
        font-size: 14px;
        margin-top: 8px;
    }

    .tasks-list__item-row {
        padding-right: 19px;
    }

    .level {
        padding-top: 14px;
    }
    .level-top .home-bottom__lvl-upgrade {
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.5px;
        margin-top: 6px;
    }
    .level-top .home-bottom__lvl-dots {
        margin-top: 10px;
    }
    .level-top__number {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0;
        margin-top: 14px;
    }
    .level-top__img {
        width: 124px;
        height: 124px;
    }
}
@media screen and (max-width: 380px) {
    .menu {
        justify-content: space-between;
    }
}
/*MAIN MEDIA END*/