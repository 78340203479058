.custom-select-container {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}
.custom-select-container * {
    box-sizing: border-box;
}
.custom-select-container.is-disabled {
    opacity: .333;
}
.custom-select-opener {
    padding: 0 24px 0 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;

    transition: .3s;
    position: relative;

    color: #231E32;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;

    outline: unset;

    border-radius: 10px;
    border: 1px solid rgba(35, 30, 50, 0.4);
}
.custom-select-opener::after {
    position: absolute;
    content: url(../img/icons/select-arrow.svg);
    right: 13px;
    top: 6px;
    transition: .3s;
}
.custom-select-container.is-open .custom-select-opener::after {
    transform: rotate(180deg);
    top: 8px;
}
.custom-select-container select {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.custom-select-panel {
    max-height: 0;
    transition: max-height .5s ease-out, overflow-y 0.1s 0.5s;
    overflow: hidden;
    border-radius: 10px;
    background: var(--gradient);
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.25);
    position: absolute;
    top: calc(100% + 6px);
    z-index: 1;
    width: 100%;
}
.custom-select-container.is-open .custom-select-panel {
    max-height: 100px;
    overflow-y: auto;
}
.custom-select-option {
    padding: 4px 21px;
    color: #000;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    cursor: pointer;
}
/*.custom-select-option[data-value="0"] {*/
/*    display: none;*/
/*}*/
.custom-select-option:first-child {
    padding-top: 8px;
}
.custom-select-option:last-child {
    padding-bottom: 8px;
}
.custom-select-option.has-focus {
    text-decoration: underline;
}
.custom-select-option.is-selected::before {
    padding-right: 0.5em;
}
.custom-select-optgroup > .custom-select-option {
    padding-left: 2em;
}
.custom-select-optgroup::before {
    content: attr(data-label);
    display: block;
    padding: 0.5em;
    color: #888;
}
