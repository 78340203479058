html, body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; 
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.App {
    min-height:100vh;
    text-align: center;
  }
  
  .game {
    background-color: #282c34;
    color: white;
    padding: 20px;
    border-radius: 5px;
  }
  
  /* button {
    margin: 10px;
    padding: 10px;
    font-size: large;
    cursor: pointer;
  } */
  

  

/*LOADER*/
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Занимает всю высоту видимой области */
}
  .loader {
    border: 2px solid rgba(255, 255, 255, 0.3); /* Тонкая серая граница */
    border-top: 2px solid #2fc908; /* Синий цвет верхней части */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



/*LEADERBORD PAGE*/
.leaderboard-container {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.leaderboard-container.visible {
  opacity: 1;
  transform: translateY(0);
  overflow-y: scroll;
  margin-bottom: 30%;
}


/*REFERAL PAGE*/
.invite-buttons {
  display: flex;
  flex-direction:row;
  align-items: center;
  gap: 0px;
}

.invite-button {
  background-color: #00aff0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  width: 80%;
  text-align: center;
}
.copy-button {
  background-color: #00aff0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  width: 80%;
  text-align: center;
}

.invite-button.premium {
  background-color: #0084ff;
}

.invite-buttons-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.invite-friend-button, .copy-link-button {
  width: 48%;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.invite-friend-button {
  background-color: #ff6f00;
  color: white;
}

.copy-link-button {
  background-color: #333;
  color: white;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.referrals-table-container {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 20px;
}

.referrals-table {
  width: 100%;
  border-collapse: collapse;
}

.referrals-table th, .referrals-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.premium-star {
  color: gold;
  margin-right: 5px;
}

.refresh-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  color:#fff;
  display: inline-block;
  width: 32px;
  height: 32px;
  --svg: url('https://api.iconify.design/bi/bell-fill.svg');
   -webkit-mask-image: var(--svg);
   mask-image: var(--svg);
   -webkit-mask-repeat: no-repeat;
   mask-repeat: no-repeat;
   -webkit-mask-size: 100% 100%;
   mask-size: 100% 100%;
}

.refresh-icon {
  font-size: 20px;
  color: #00aff0;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.refresh-button:hover .refresh-icon {
  transform: rotate(360deg);
}



.tap-cost {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.9);
    color: #000;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 18px;
    animation: fadeOut 1s forwards;
    z-index: 100;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
    100% {
        opacity: 0;
        transform: translate(-50%, -30px);
    }
}

.coin-container {
    position: relative;
    display: inline-block;
}
